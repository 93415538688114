.main-container {
  max-width: 1600px;
  margin: auto !important;
}

/* At the top level of your code */
@media screen and (min-width: 991px) {
  .owl-item {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .owl-stage {
    flex-wrap: nowrap !important;
    gap: 15px;
  }
  .career-stage {
    flex-wrap: wrap !important;
    gap: 15px;
    justify-content: flex-start !important;
  }
  .career-stage .owl-item {
    width: 32%;
  }
}

.container-fluid p.text-center.text-white {
  font-weight: bold !important;
  background-color: hsla(0, 0%, 50%, 0.6);
  border-radius: 10px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.container-fluid.services .service-item,
.container-fluid.services .service-item .p-5,
.testmonials .owl-item .text-center,
.testmonials .owl-item .testimonial-text,
.container-fluid.career .text-center {
  height: 100%;
}
.container-fluid.old-projects .container .slideInUp .text-center {
  height: 170px;
}
.testmonials .owl-item .testimonial-text,
.text-truncate {
  white-space: normal !important;
}

.container-fluid.client-carousel .carousel-item img {
  height: 93vh;
  object-fit: "cover";
}

@media screen and (max-width: 1024px) {
  .container-fluid.client-carousel .carousel-item img {
    height: 40vh;
  }
}

.hero-header {
  height: 412px;
}
.hero-header .text-center.text-white {
  width: 80% !important;
}

@media screen and (max-width: 1024px) {
  .form-container .col-6 {
    width: 100%;
  }
  .form-container.p-5 {
    padding: 0.5rem !important;
  }
  .form-container.p-5 form > .row {
    justify-content: center;
  }

  .hero-header.p-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .hero-header .text-center.text-white {
    width: 90% !important;
  }
}
@media screen and (min-width: 1024px) {
  .testimonial-text,
  .service-item .position-relative {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .testimonial-text .testimonial-footer {
    margin-bottom: 30px;
  }
}
