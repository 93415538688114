/********** Template CSS **********/

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  margin-left: 0;
}

h1,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}

h3,
h4,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.font-weight-medium {
  font-weight: 500 !important;
}

.btn {
  font-family: "Jost", sans-serif;
  font-weight: 600;
  transition: 0.5s;
}

.btn-primary,
.btn-secondary,
.btn-primary:hover,
.btn-secondary:hover {
  color: #ffffff;
}

.btn-primary:hover {
  background: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Jost", sans-serif;
  position: relative;
  margin-left: 30px;
  padding: 30px 0;
  font-size: 20px;
  font-weight: 500;
  color: var(--dark);
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 5px;
    bottom: 0;
    left: 50%;
    background: var(--primary);
    transition: 0.5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    margin-left: 0;
    padding: 10px 0;
  }
}

.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 15, 45, 0.5);
  z-index: 1;
}

@media (max-width: 576px) {
  .carousel-caption h5 {
    font-size: 14px;
    font-weight: 500 !important;
  }

  .carousel-caption h1 {
    font-size: 30px;
    font-weight: 600 !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
}

.carousel-indicators {
  align-items: flex-end;
}

.carousel-indicators [data-bs-target] {
  width: 5px;
  height: 25px;
}

.carousel-indicators .active {
  height: 45px;
}

.hero-header {
  background: linear-gradient(rgba(18, 15, 45, 0.5), rgba(18, 15, 45, 0.5)),
    top right no-repeat;
  background-size: cover;
}

.service-item div {
  z-index: 1;
}

.service-item div::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 2px 2px 0 0;
  transition: 0.5s;
  z-index: -1;
}

.service-item div:hover::after {
  width: 100%;
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

.bg-offer {
  background: linear-gradient(rgba(18, 15, 45, 0.5), rgba(18, 15, 45, 0.5)),
    url(../../config/images/carousel-2.jpg) top right no-repeat;
  background-size: cover;
}

.testimonial-carousel .owl-dots {
  height: 45px;
  margin-top: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 5px;
  height: 25px;
  background: #dddddd;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  height: 45px;
  background: var(--primary);
}

.testimonial-carousel .owl-item .testimonial-text,
.testimonial-carousel .owl-item .testimonial-text * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-text {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-text * {
  color: #ffffff !important;
}

.blog-date {
  position: absolute;
  width: 90px;
  height: 65px;
  top: 75px;
  left: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--secondary);
}

.blog-date::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -25px;
  left: 0;
  border: 13px solid;
  border-color: transparent #d34351 #d34351 transparent;
  z-index: -1;
}
