@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?7ecca773380623aac2649a7edcc0a3f2") format("truetype"),
url("./flaticon.woff?7ecca773380623aac2649a7edcc0a3f2") format("woff"),
url("./flaticon.woff2?7ecca773380623aac2649a7edcc0a3f2") format("woff2"),
url("./flaticon.eot?7ecca773380623aac2649a7edcc0a3f2#iefix") format("embedded-opentype"),
url("./flaticon.svg?7ecca773380623aac2649a7edcc0a3f2#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-security-system:before {
    content: "\f101";
}
.flaticon-cctv:before {
    content: "\f102";
}
.flaticon-socket:before {
    content: "\f103";
}
.flaticon-setup:before {
    content: "\f104";
}
.flaticon-surveillance:before {
    content: "\f105";
}
.flaticon-recorder:before {
    content: "\f106";
}
.flaticon-wireless:before {
    content: "\f107";
}
.flaticon-ip-camera:before {
    content: "\f108";
}
.flaticon-double:before {
    content: "\f109";
}
.flaticon-camera:before {
    content: "\f10a";
}
